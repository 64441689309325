import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import {
  useEditor,
  EditorContent,
  type Content,
  type Extensions,
  type EditorContentProps,
  type EditorOptions,
  type Editor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { forwardRef, type Ref, useEffect, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import { Button, useMergeRefs } from '@/ui';
import { LinkFloatingMenu } from './LinkFloatingMenu';
import { Toolbar } from './Toolbar';
import './styles.scss';
import { useLineClamp } from './useLineClamp';

export type RichTextEditorProps = Omit<
  EditorContentProps,
  'editor' | 'onChange' | 'onKeyUp' | 'onKeyDown'
> & {
  containerRef?: Ref<HTMLDivElement>;
  containerProps?: MotionFlexProps;
  contentRef?: Ref<HTMLDivElement>;
  disableToolbar?: boolean;
  value: Content;
  lineClamp?: number;
  onKeyUp?: (event: KeyboardEvent) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onCreate?: EditorOptions['onCreate'];
  onChange?: (value: string, text: string) => void;
};

// Writing text then clearing it all results in empty tags
// which we don't want to persist
const cleanHTML = (html: string) => {
  const tags = [
    '<p></p>',
    '<h1></h1>',
    '<h2></h2>',
    '<h3></h3>',
    '<h4></h4>',
    '<h5></h5>',
    '<h6></h6>',
  ];
  return tags.includes(html.trim()) ? '' : html;
};

const RichTextEditor = forwardRef<Editor, RichTextEditorProps>(
  function RichTextEditorWithRef(
    {
      autoFocus,
      containerRef,
      containerProps,
      contentRef,
      readOnly,
      disableToolbar,
      value,
      placeholder,
      onCreate,
      onChange,
      onKeyUp,
      onKeyDown,
      lineClamp,
      ...contentProps
    },
    ref
  ) {
    const [internalContentElement, setInternalContentElement] =
      useState<HTMLDivElement | null>(null);
    const contentRefs = useMergeRefs(setInternalContentElement, contentRef);
    const { isClamped, isExpanded, setExpanded, editorContentStyles } =
      useLineClamp(lineClamp, internalContentElement);

    const extensions = useMemo(() => {
      const extensions: Extensions = [
        StarterKit,
        Link.configure({
          autolink: false,
          openOnClick: readOnly ? true : false,
        }),
      ];

      extensions.push(
        Placeholder.configure({
          placeholder,
          showOnlyWhenEditable: false,
        })
      );

      return extensions;
    }, [readOnly, placeholder]);

    const editor = useEditor({
      editable: !readOnly,
      extensions,
      content: value,
      injectCSS: false,
      editorProps: {
        handleDOMEvents: {
          keyup: (view, event) => onKeyUp?.(event),
          keydown: (view, event) => onKeyDown?.(event),
        },
      },
      onUpdate: ({ editor }) => {
        const html = cleanHTML(editor.getHTML());
        const text = editor.getText();
        onChange?.(html, text);
      },
      onCreate: ({ editor }) => {
        if (autoFocus) {
          // Timeout works around a race condition with Chakra when the editor
          // is used inside a popup or modal
          setTimeout(() => editor.commands.focus(), 100);
        }
        onCreate?.({ editor });
      },
    });

    useEffect(() => {
      if (typeof ref === 'function') {
        ref(editor);
      } else if (ref) {
        ref.current = editor;
      }
    }, [editor, ref]);

    useUpdateEffect(() => {
      editor?.setOptions({
        editable: !readOnly,
        extensions,
      });
    }, [readOnly, extensions]);

    return (
      // Container is required
      // https://github.com/ueberdosis/tiptap/issues/2658
      <MotionFlex
        align="flex-start"
        animate={{ opacity: 1 }}
        direction="column"
        flex="1"
        initial={{ opacity: 0 }}
        pos="relative"
        ref={containerRef}
        {...containerProps}
      >
        <LinkFloatingMenu editor={editor} readOnly={readOnly} />

        {editor && (
          <Toolbar editor={editor} readOnly={readOnly || disableToolbar} />
        )}

        <EditorContent
          {...contentProps}
          editor={editor}
          // @ts-expect-error useMergeRefs doesn't produce the right type when one ref is a useState setter, but the functionality works
          ref={contentRefs}
          style={{
            ...editorContentStyles,
            ...contentProps.style,
          }}
        />
        {isClamped && !isExpanded && (
          <Button
            display="inline-block"
            minW="auto"
            variant="link"
            onClick={() => setExpanded(!isExpanded)}
          >
            more
          </Button>
        )}
      </MotionFlex>
    );
  }
);

export default RichTextEditor;
