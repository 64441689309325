import { type Editor, isTextSelection } from '@tiptap/react';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AiOutlineOrderedList, AiOutlineUnorderedList } from 'react-icons/ai';
import {
  BiBold,
  BiItalic,
  BiLink,
  BiStrikethrough,
  BiUnlink,
} from 'react-icons/bi';
import { GoQuote } from 'react-icons/go';
import { MdFormatClear } from 'react-icons/md';
import { useDebounceLoading } from '@/hooks/useDebounceLoading';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { Box, Flex, type FlexProps, Portal } from '@/ui';
import { withHttp } from '../../../../../utils/url';
import { ControlledBubbleMenu } from './BubbleMenu';
import { ToolbarButton } from './ToolbarButton';

type Props = FlexProps & {
  editor: Editor;
  readOnly?: boolean;
};

const ToolbarButtonSet = (props: FlexProps) => {
  return <Flex gap="1" justify="space-between" {...props} />;
};

const Divider = () => <Box display={{ base: 'none', md: 'block' }} w="18px" />;

export const Toolbar = ({ editor, readOnly, ...flexProps }: Props) => {
  const { t } = useTranslation('richTextEditor');
  const menuRef = useRef<HTMLDivElement | null>(null);
  const isMobileBreakpoint = useIsMobileBreakpoint();

  const setLink = useCallback(() => {
    const previousUrl = editor?.getAttributes('link').href;
    let url = window.prompt(t('url_prompt'), previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // format link
    url = withHttp(url);

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  }, [editor, t]);

  const { state } = editor;
  const { doc, selection } = state;
  const { ranges, empty } = selection;
  const from = Math.min(...ranges.map((range) => range.$from.pos));
  const to = Math.max(...ranges.map((range) => range.$to.pos));

  // Sometimes checking for `selection.empty` is not enough. Double-clicking on
  // an empty paragraph returns a node size of 2 so we also need to check
  // for an empty text size
  const isEmptyTextBlock =
    !doc.textBetween(from, to).length && isTextSelection(state.selection);

  // When clicking on a element inside the menu the editor "blur" event
  // is called and the bubble menu item is focused. In this case we should
  // consider the menu as part of the editor and keep showing the menu
  const isChildOfMenu = menuRef.current?.contains(document.activeElement);

  // Debounce editor focus so when clicking on a toolbar button, the blur event
  // doesn't cause the toolbar to disappear before the click is registered
  const hasEditorFocus = editor.view.hasFocus() || !!isChildOfMenu;
  const debouncedHasEditorFocus = useDebounceLoading(hasEditorFocus, 10);

  let shouldShowToolbar = true;
  if (
    !editor.isEditable ||
    !debouncedHasEditorFocus ||
    isEmptyTextBlock ||
    empty ||
    readOnly
  ) {
    shouldShowToolbar = false;
  }

  if (!shouldShowToolbar) {
    return null;
  }

  const bold = (
    <ToolbarButton
      isActive={editor.isActive('bold')}
      tooltip={t('tooltips.bold')}
      onClick={() => editor.chain().focus().toggleBold().run()}
    >
      <BiBold />
    </ToolbarButton>
  );

  const italic = (
    <ToolbarButton
      isActive={editor.isActive('italic')}
      tooltip={t('tooltips.italic')}
      onClick={() => editor.chain().focus().toggleItalic().run()}
    >
      <BiItalic />
    </ToolbarButton>
  );

  const strikethrough = (
    <ToolbarButton
      isActive={editor.isActive('strike')}
      tooltip={t('tooltips.strikethrough')}
      onClick={() => editor.chain().focus().toggleStrike().run()}
    >
      <BiStrikethrough />
    </ToolbarButton>
  );

  const link = (
    <ToolbarButton
      className={editor.isActive('link') ? 'is-active' : ''}
      tooltip={t('tooltips.link')}
      onClick={setLink}
    >
      <BiLink />
    </ToolbarButton>
  );

  const unlink = (
    <ToolbarButton
      disabled={!editor.isActive('link')}
      tooltip={t('tooltips.unlink')}
      onClick={() => editor.chain().focus().unsetLink().run()}
    >
      <BiUnlink />
    </ToolbarButton>
  );

  const bulletList = (
    <ToolbarButton
      isActive={editor.isActive('bulletList')}
      tooltip={t('tooltips.bulletList')}
      onClick={() => editor.chain().focus().toggleBulletList().run()}
    >
      <AiOutlineUnorderedList />
    </ToolbarButton>
  );

  const orderedList = (
    <ToolbarButton
      isActive={editor.isActive('orderedList')}
      tooltip={t('tooltips.orderedList')}
      onClick={() => editor.chain().focus().toggleOrderedList().run()}
    >
      <AiOutlineOrderedList />
    </ToolbarButton>
  );

  const quote = (
    <ToolbarButton
      isActive={editor.isActive('blockquote')}
      tooltip={t('tooltips.quote')}
      onClick={() => editor.chain().focus().toggleBlockquote().run()}
    >
      <GoQuote />
    </ToolbarButton>
  );

  const clearFormatting = (
    <ToolbarButton
      tooltip={t('tooltips.clear')}
      onClick={() => editor.chain().focus().clearNodes().unsetAllMarks().run()}
    >
      <MdFormatClear />
    </ToolbarButton>
  );

  return (
    <Portal appendToParentPortal={false}>
      <ControlledBubbleMenu
        containerProps={{ zIndex: 'calc(var(--chakra-zIndices-modal) + 1)' }}
        editor={editor}
        ref={menuRef}
      >
        <Flex
          align="center"
          shadow="0px 0px 6px 1px rgba(0, 0, 0, 0.08)"
          {...flexProps}
          bg="white"
          borderColor="gray.100"
          borderRadius="8"
          borderWidth="1px"
          direction={{ base: 'column', md: 'row' }}
          gap={{ base: 2, md: 0 }}
          p="2"
        >
          {isMobileBreakpoint ? (
            <>
              <ToolbarButtonSet>
                {bold}
                {italic}
                {strikethrough}
              </ToolbarButtonSet>

              <ToolbarButtonSet>
                {link}
                {unlink}
                {bulletList}
                {orderedList}
                {quote}
                {clearFormatting}
              </ToolbarButtonSet>
            </>
          ) : (
            <>
              <ToolbarButtonSet>
                {bold}
                {italic}
                {strikethrough}
              </ToolbarButtonSet>

              <Divider />

              <ToolbarButtonSet>
                {link}
                {unlink}
              </ToolbarButtonSet>

              <Divider />

              <ToolbarButtonSet>
                {bulletList}
                {orderedList}
                {quote}
              </ToolbarButtonSet>

              <Divider />

              {clearFormatting}
            </>
          )}
        </Flex>
      </ControlledBubbleMenu>
    </Portal>
  );
};
