import { Button, type ButtonProps, Tooltip, forwardRef } from '@/ui';

type Props = ButtonProps & {
  tooltip?: string;
};

export const ToolbarButton = forwardRef<Props, 'button'>(
  ({ tooltip, ...props }, ref) => {
    return (
      <Tooltip hasArrow isDisabled={!tooltip} label={tooltip}>
        <Button
          bg={props.isActive ? 'gray.100' : undefined}
          minW="35px"
          px={0}
          ref={ref}
          size="sm"
          variant="secondary"
          {...props}
        />
      </Tooltip>
    );
  }
);
