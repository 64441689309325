import { type Editor, FloatingMenu } from '@tiptap/react';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import { Box, Button, type ButtonProps, Flex } from '@/ui';
import { withHttp } from '../../../../utils/url';

const LinkButton = (props: ButtonProps) => (
  <Button colorScheme="dark" size="sm" variant="link" {...props} />
);

const Divider = () => <Box mx="2">|</Box>;

type Props = {
  editor: Editor | null | undefined;
  readOnly?: boolean;
};

export const LinkFloatingMenu = ({ editor, readOnly }: Props) => {
  const { t } = useTranslation('richTextEditor');

  const handleOpenClick = () => {
    if (editor) {
      const attrs = editor.getAttributes('link');
      window.open(attrs.href, attrs.target);
    }
  };

  const handleChangeClick = () => {
    const previousUrl = editor?.getAttributes('link').href;
    let url = window.prompt(t('url_prompt'), previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }

    // format link
    url = withHttp(url);

    // update link
    editor
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  };

  const handleRemoveClick = () => {
    editor
      ?.chain()
      .focus()
      .extendMarkRange('link')
      .unsetLink()
      .setTextSelection({
        // Removes selection while keeping the cursor in the same position
        from: editor.state.selection.from,
        to: editor.state.selection.from,
      })
      .run();
  };

  if (!editor) {
    return null;
  }

  return (
    <FloatingMenu
      editor={editor}
      tippyOptions={{ placement: 'bottom' }}
      shouldShow={({ editor, state }) => {
        if (readOnly) {
          return false;
        }
        const attrs = editor.getAttributes('link');
        return !!attrs?.href && state.selection.empty;
      }}
    >
      <Flex
        align="center"
        bg="white"
        borderColor="gray.100"
        borderRadius="8"
        borderWidth="1px"
        p="2"
        shadow="0px 0px 6px 1px rgba(0, 0, 0, 0.08)"
      >
        <LinkButton onClick={handleOpenClick}>
          <FiExternalLink style={{ marginRight: 4 }} />
          {t('link_menu.go_to_link')}
        </LinkButton>
        <Divider />
        <LinkButton onClick={handleChangeClick}>
          {t('link_menu.change')}
        </LinkButton>
        <Divider />
        <LinkButton onClick={handleRemoveClick}>
          {t('link_menu.remove')}
        </LinkButton>
      </Flex>
    </FloatingMenu>
  );
};
