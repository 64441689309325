import { type CSSProperties, useEffect, useState } from 'react';

export const useLineClamp = (
  lineClamp: number | undefined,
  editorContent: HTMLDivElement | null
) => {
  const [isClamped, setClamped] = useState(false);
  const [isExpanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!lineClamp) {
      return;
    }

    function handleResize() {
      if (editorContent) {
        setClamped(editorContent.scrollHeight > editorContent.clientHeight);
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [lineClamp, editorContent]);

  const editorContentStyles: CSSProperties =
    lineClamp && !isExpanded
      ? {
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitLineClamp: lineClamp,
          WebkitBoxOrient: 'vertical',
        }
      : {};

  return {
    isClamped,
    isExpanded,
    setExpanded,
    editorContentStyles,
  };
};
