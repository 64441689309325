import { useMergeRefs } from '@floating-ui/react';
import { useFloating, autoUpdate, offset, flip } from '@floating-ui/react-dom';
import { type Editor, isNodeSelection, posToDOMRect } from '@tiptap/core';
import { type ReactNode, useLayoutEffect } from 'react';
import { Box, type BoxProps, forwardRef } from '@/ui';

type Props = {
  editor: Editor;
  children: ReactNode;
  containerProps?: BoxProps;
};

// Adapted from https://github.com/ueberdosis/tiptap/issues/2305#issuecomment-1020665146
export const ControlledBubbleMenu = forwardRef(
  ({ editor, children, containerProps }: Props, ref) => {
    const { floatingStyles, refs } = useFloating({
      strategy: 'fixed',
      whileElementsMounted: autoUpdate,
      placement: 'top',
      middleware: [
        offset({ mainAxis: 8 }),
        flip({
          padding: 8,
          boundary: editor.options.element,
          fallbackPlacements: ['top-start', 'top-end'],
        }),
      ],
    });

    const containerRef = useMergeRefs([ref, refs.setFloating]);

    useLayoutEffect(() => {
      const { ranges } = editor.state.selection;
      const from = Math.min(...ranges.map((range) => range.$from.pos));
      const to = Math.max(...ranges.map((range) => range.$to.pos));

      refs.setReference({
        getBoundingClientRect() {
          if (isNodeSelection(editor.state.selection)) {
            const node = editor.view.nodeDOM(from) as HTMLElement;

            if (node) {
              return node.getBoundingClientRect();
            }
          }

          return posToDOMRect(editor.view, from, to);
        },
      });
    }, [refs, editor.view, editor.state.selection]);

    return (
      <Box
        className="tiptap-toolbar"
        ref={containerRef}
        {...containerProps}
        style={floatingStyles}
      >
        {children}
      </Box>
    );
  }
);
